export const appShellKeywords: [string, string][] = [
  ['Iuphoria', ''],
  ['Start', ''],
  ['Home', ''],
  ['Verfügbare Gesetze', 'norm'],
  ['Vorschriften', 'norm'],
  ['Impressum', 'iuphoria/kontakt'],
  ['Kontakt', 'iuphoria/kontakt'],
  ['Datenschutzerklärung', 'iuphoria/datenschutz'],
  ['DSGVO', 'iuphoria/datenschutz'],
  ['FAQ', 'iuphoria/faq'],
  ['Feedback', 'iuphoria/feedback'],
];
