export const keywordsVarAndFilename = 'keywords';

import { AllCoursesFileAndVarnames } from '@mr/ecourse-interfaces';

export const tooltipClass = 'mr-tooltip';
export const definitionAddition = 'Definition';

export const definitionsFileAndVarnames: AllCoursesFileAndVarnames = {
  allCoursesExportsFilename: 'all-courses-definition-components.ts',
  allCoursesExportsArrayname: 'allCoursesDefinitionComponents',
  allCoursesRoutesExportFilename: null,
  allCoursesRoutesExportArrayname: null,
};

export const definitionComponentsFolder = 'definition-components';

export const contentCardDefinitionCategory = 'Definition';


export const keywordReferenceSelector = 'mr-keyword-reference';
export const filepathAttributeName = 'filepath';
export const classnameAttributeName = 'classname';
export const lazyLoadingMethodName = 'loadcomp';
export const urlPathAttributeName = 'urlpath';
export const keywordAttributeName = 'keyword';
export const compInfoImportsVarName = 'imports';
