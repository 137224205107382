import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ViewContainerRef,
  ChangeDetectorRef,
  ComponentRef,
  Inject,
} from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatLegacyTooltipModule, MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { testingURL, tooltipDefaults, pageNotFoundRoute, searchBoxHintText, searchBoxId } from '@mr/ecourse-config';
import {
  MatLegacyAutocompleteModule as MatAutocompleteModule,
  MatLegacyAutocompleteOrigin as MatAutocompleteOrigin,
} from '@angular/material/legacy-autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { DOCUMENT, NgIf } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SearchBoxComponent } from '@mr/ecourse-search-box-feature';
import { LegalInfoButtonsComponent } from '@mr/legal-info-buttons';
import { SeoService } from '@mr/shared-seo-service';
import { Meta } from '@angular/platform-browser';
import { TextContentStylingComponent } from '@mr/shared-ui-text-content-styling';
import { EcourseLazyLoadingService } from '@mr/ecourse-lazy-loading';
import { PreviousNextChapterComponent } from '@mr/ecourse/chapters/related-chapters/related-chapters-feature';
import { BehaviorSubject } from 'rxjs';
import { webSiteBaseURL, websiteName } from '@mr/ecourse-config';
// import { TouchEventsStoreService } from '@mr/shared-touch-events-store';
// import { CourseModuleToolbarModule } from '@mr/ecourse-course-module-toolbar';

@Component({
  selector: 'mr-ecourse-app-shell',
  standalone: true,
  imports: [
    NgIf,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    RouterOutlet,
    RouterLink,
    MatLegacyTooltipModule,
    MatButtonModule,
    MatAutocompleteModule,
    TextContentStylingComponent,
    // CourseModuleToolbarModule,
  ],
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults }],
})
export class AppShellComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private ref: ChangeDetectorRef,
    private seoService: SeoService,
    private lazyLoading: EcourseLazyLoadingService,
    private metaService: Meta,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  isTestingSite = false;
  metisImgSrc: string | undefined = undefined;

  searchBoxHintText = searchBoxHintText;

  menuIsLoading = false;
  searchBoxIsLoaded = false;
  prevNextChaptersIsLoading = false;

  routerOutletComp: any = null;

  prevNextChapterComp: ComponentRef<PreviousNextChapterComponent> | null = null;
  $prevNextChapterData = new BehaviorSubject(null);

  @ViewChild('menubutton', { read: ElementRef }) menubutton!: ElementRef;
  @ViewChild('previousNextChapter', { read: ViewContainerRef }) previousNextChapter!: ViewContainerRef;
  @ViewChild('searchBox', { read: ViewContainerRef }) searchBox!: ViewContainerRef;
  @ViewChild('toolbar', { read: MatAutocompleteOrigin }) toolbar!: MatAutocompleteOrigin;
  @ViewChild('legalInfoButtons', { read: ViewContainerRef }) legalInfoButtons!: ViewContainerRef;
  @ViewChild('sidenav', { read: MatSidenav }) sidenav!: MatSidenav;

  ngOnInit() {
    this.updateSeoSettings();
  }

  ngAfterViewInit() {
    this.lazyLoadAppShellComponents();
  }

  private updateSeoSettings() {
    // TMP
    this.tmpUpdateCanonicalUrl();

    this.isTestingSite = location.origin.toLowerCase().includes(testingURL.toLowerCase());
    this.setRobotsToNoIndexOn404AndTestingSite();
    this.seoService.setSiteMetaTags();
    this.seoService.updateCanonicalUrl();
  }

  tmpUpdateCanonicalUrl() {
    // TMP: remove after SSR
    let linkElement: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"');
    if (linkElement === null) {
      linkElement = this.doc.createElement('link');
      linkElement?.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(linkElement);
    }
    const canonicalURL = webSiteBaseURL + window.location.pathname;
    linkElement?.setAttribute('href', canonicalURL);
  }

  private lazyLoadAppShellComponents() {
    // menu
    const menuDelay: number | null = this.lazyLoading.getDelay('menu');
    if (menuDelay !== null) {
      setTimeout(() => {
        if (!this.menuIsLoading) {
          this.menuIsLoading = true;
          this.loadMenu(false);
        }
      }, menuDelay);
    }

    // searchBox
    const searchboxDelay: number | null = this.lazyLoading.getDelay('searchBox');
    if (searchboxDelay !== null) {
      setTimeout(() => {
        this.loadSearchBox(null);
      }, searchboxDelay);
    }

    // prevNextChapter
    const prevNextChapterDelay: number | null = this.lazyLoading.getDelay('prevNextChapter');
    if (prevNextChapterDelay !== null) {
      setTimeout(() => {
        this.loadPrevNextChapters();
      }, prevNextChapterDelay);
    }
  }

  menuClicked($event: Event) {
    $event.preventDefault;
    $event.stopPropagation();
    if (!this.menuIsLoading) {
      this.menuIsLoading = true;
      this.loadMenu(true);
    } else {
      this.sidenav.toggle();
    }
  }

  loadMenu(openAfterwords: boolean) {
    import('@mr/legal-info-buttons').then(barrel => {
      const legalInfoButtonsComp: ComponentRef<LegalInfoButtonsComponent> = this.legalInfoButtons.createComponent(barrel.LegalInfoButtonsComponent);
      legalInfoButtonsComp.instance.closeSidenav.subscribe(() => this.sidenav.close());
      this.ref.detectChanges();
      if (openAfterwords) {
        this.sidenav.toggle();
      }
    });
  }

  loadSearchBox($event: Event | null) {
    $event?.preventDefault();
    $event?.stopPropagation();

    if (!this.searchBoxIsLoaded) {
      import('@mr/ecourse-search-box-feature').then(barrel => {
        const searchBoxComp: ComponentRef<SearchBoxComponent> = this.searchBox.createComponent(barrel.SearchBoxComponent);
        searchBoxComp.instance.toolbar = this.toolbar;
        searchBoxComp.instance.updateFocus.subscribe(() => this.refocusAndScrollToTop());
        this.searchBoxIsLoaded = true;
        this.ref.detectChanges();
        if ($event !== null) {
          document.getElementById(searchBoxId)?.focus();
        }
      });
    }
  }

  loadPrevNextChapters() {
    if (this.prevNextChaptersIsLoading == false) {
      this.prevNextChaptersIsLoading = true;
      import('@mr/ecourse/chapters/related-chapters/related-chapters-feature').then(barrel => {
        this.prevNextChapterComp = this.previousNextChapter.createComponent(barrel.PreviousNextChapterComponent);
        this.prevNextChapterComp.instance.related = this.$prevNextChapterData;
        this.updateRelatedChaptersData();
      });
    }
  }

  routeToFeedback() {
    this.router.navigate(['iuphoria/feedback'], { state: { url: this.router.url } });
  }

  routeActivated(componentReference: any) {
    this.routerOutletComp = componentReference;
    this.updateSeoData();
    this.updateMetisData();
    this.refocusAndScrollToTop();
    this.updateRelatedChaptersData();
  }

  updateSeoData() {
    this.setRobotsToNoIndexOn404AndTestingSite();
    this.seoService.updateCanonicalUrl();
    this.seoService.updateTitle(this.routerOutletComp['title']);
    this.seoService.updateDescription(this.routerOutletComp['description']);
  }

  updateMetisData() {
    const metisDelay: number | null = this.lazyLoading.getDelay('metis');
    setTimeout(() => {
      this.metisImgSrc = this.routerOutletComp['metisImgSrc'];
    }, metisDelay || 0);
  }

  updateRelatedChaptersData() {
    if (this.prevNextChapterComp) {
      this.$prevNextChapterData.next(structuredClone(this.routerOutletComp['relatedChapters']));
      this.ref.detectChanges();
    }
  }

  refocusAndScrollToTop() {
    // refocus (to make virtual keyboard on mobile hide)
    (this.menubutton.nativeElement as HTMLButtonElement).focus();
    (this.menubutton.nativeElement as HTMLButtonElement).blur();

    // scroll to top
    const mainContent: HTMLElement | null = document.getElementById('main-content');
    const options: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    };
    // setTimeout as it did not work with going back to root
    // 500ms is neccessary, so that it does not get interrupted by focus/blur hiding the keyboard on mobile
    setTimeout(() => mainContent?.scrollIntoView(options), 500);
  }

  private setRobotsToNoIndexOn404AndTestingSite() {
    if (this.router.url === '/' + pageNotFoundRoute || location.origin.toLowerCase().includes(testingURL.toLowerCase())) {
      this.metaService.updateTag({ name: 'robots', content: 'noindex' });
    } else {
      this.metaService.updateTag({ name: 'robots', content: 'index, follow' });
    }
  }
}
