export const hintsWordStyle = 'IuphoriaHint';
export const hintsDocToHtmlClass = 'iuphoriahint';
export const hintSelector = 'mr-hint';
export const hintTypeIconMap: [string, string][] = [
  ['achtung', 'error'],
  ['vorsicht', 'error'],
  ['warnung', 'error'],
  ['aufgepasst', 'warning'], // TODO: difference warning/error?

  ['wichtig', 'tips_and_updates'], // TODO: why not warning?

  ['tip', 'tips_and_updates'],
  ['tips', 'tips_and_updates'],
  ['tipp', 'tips_and_updates'],
  ['tipps', 'tips_and_updates'],
  ['aha', 'tips_and_updates'],
  ['verständnis', 'tips_and_updates'],
  ['hinweis', 'tips_and_updates'],
  ['hinweise', 'tips_and_updates'],

  ['hintergrund', 'info'],
  ['infos', 'info'],
  ['info', 'info'],
  ['information', 'info'],
  ['informationen', 'info'],

  ['baustein', 'extension'],
  ['bausteine', 'extension'],

  ['smiley', 'mood'],

  ['beispiel', 'local_library'],
  ['beispiele', 'local_library'],
  ['bsp.', 'local_library'],
  ['bsp', 'local_library'],
  ['gegenbeipsiel', 'local_library'],
  ['gegenbeispiele', 'local_library'],
  ['gegenbsp.', 'local_library'],
  ['klausur', 'local_library'], // do we have something more specific for "klausur"?

  ['näher', 'read_more'], // eg. „read_more“; „east“ oder „arrow_forward“ oder „trending_flat“
  ['näheres', 'read_more'],
  ['vertiefung', 'read_more'],
  ['verweis', 'read_more'],

  ['zitat', 'format_quote'],
];

// Warning: error looks better then warning or feedback
// Hinweis: lightbulb; lightbulb_circle instead the one with the circles around?
