export const websiteName = 'Iuphoria';
export const testingURL = 'https://testing.iuphoria.de';
export const localHostURL = 'http://localhost:4200';
export const webSiteBaseURL = 'https://www.iuphoria.de';

export const logsFolderPath = 'logs';

export const logFilenames = {
    statutesDownload: logsFolderPath + '/log-statutes-download',
    statuteConversion: logsFolderPath + '/log-statute-conversion',
    docToHtml: logsFolderPath + '/log-doc-to-html',
    htmlToComponents: logsFolderPath + '/log-html-to-components',
    ecourse: logsFolderPath + '/log-ecourse',
}
export const ecourseClientSrcPath = './apps/ecourse/client/src'; // https://stackoverflow.com/questions/61352653/angular-8-sitemap-and-robots-txt

// SEO: also add sitemap.txt and robots.txt to assets-info in workspace.json/angular.json
export const ecourseSitemapFilename = 'sitemap.txt';
