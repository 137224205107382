import { Routes } from '@angular/router';
import { pageNotFoundRoute } from '@mr/ecourse-config';

export const appRoutes: Routes = [
  {
    path: 'norm',
    loadChildren: () => import('@mr/ecourse-norms-display').then( m => m.normRoutes),
  },
  {
    path: 'iuphoria/feedback',
    loadComponent: () => import('@mr/feedback-page'),
  },
  {
    path: '',
    loadChildren: () => import('@mr/ecourse/app-shell/ecourse-main-pages-ui').then(m => m.clientPagesUiRoutes),
  },
  {
    path: '',
    loadChildren: () => import('content/ecourse/courses-standalone/all-courses-routes').then(m => m.courseContentRoutes),
  },
  {
    path: 'pseudo-webpack-routes',
    loadChildren: () => import('content/ecourse/courses-standalone/pseudo-all-courses-routes').then( m => m.pseudoCourseContentRoutes),
  },
  {
    path: '**',
    redirectTo: pageNotFoundRoute,
  },
];
