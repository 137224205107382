import { AllCoursesFileAndVarnames, CategoryIconMap } from '@mr/ecourse-interfaces';

export const folderContentCardComponents = 'content-card-components';

export const contentCardsFileAndVarnames: AllCoursesFileAndVarnames = {
  allCoursesExportsFilename: 'all-courses-content-card-components.ts',
  allCoursesExportsArrayname: 'allCoursesContentCardComponents',
  allCoursesRoutesExportFilename: null,
  allCoursesRoutesExportArrayname: null,
};

export const complexContentCardSelector = 'mr-complex-content-card';

export const stringToSetCardVisibilityTrue = 'Karte';
export const contentCardAttributes = {
  category: 'mrCategory',
  title: 'mrTitle',
  subTitle: 'mrSubtitle',
  cardVisibility: 'mrCard', // can be set "true" or "false" (as string!) --> CardVisibilityOptions
};

export const categoryIconMap: CategoryIconMap = {
  'Definition': 'extension',
  'Gliederung': 'format_list_numbered',
  'Zeitablauf': 'timeline',
  'Sachverhalt': 'record_voice_over',
  'Bild': 'photo',
  'Video': 'local_movies',
  'Text': 'article',
  'Sonstiges': '',
};
