import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from '@mr/shared-util-environments';
import { disableConsoleLog } from '@mr/shared-util-nestjs-logger';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideHttpClient } from '@angular/common/http';
import { appRoutes } from './app/ecourse-app-routes';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Angulartics2Module } from 'angulartics2';

if (environment.production) {
  enableProdMode();
  disableConsoleLog();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(), 
    importProvidersFrom(
      Angulartics2Module.forRoot(),
      ),
    provideRouter(appRoutes),
    provideAnimations(),
    
  ],
}).catch((error) => {
  console.log(error);
});
