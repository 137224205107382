import { NodemailConfig } from '@mr/ecourse-interfaces';

export const institutions: string[] = [
  'Universität',
  'Fachhochschule',
  'Schule',
  'Unternehmen',
  'Behörde',
  'Sonstiges',
];

export const fachbereiche: string[] = [
  'Rechtswissenschaften',
  'Sozialwissenschaften',
  'Naturwissenschaften',
  'Ingenieurswissenschaften',
  'Sonstiges',
];

// prod mode: allInkl
export const emailSenderProd: NodemailConfig = {
  service: 'allInkl',
  access: {
    host: 'w0173fda.kasserver.com',
    port: 465,
    secure: true,
    user: undefined, // --> .env
    pw: undefined, // --> .env
  },
  mailOptions: {
    from: 'feedback@iuphoria.de',
    to: 'feedback@iuphoria.de',
  },
};

// dev mode: mailtrap
export const emailSenderDev: NodemailConfig = {
  service: 'mailtrap',
  access: {
    host: 'smtp.mailtrap.io',
    port: 2525,
    user: undefined, // --> .env
    pw: undefined, // --> .env
  },
  mailOptions: {
    from: '9c9fdb78b435f6@inbox.mailtrap.io',
    to: '9c9fdb78b435f6@inbox.mailtrap.io',
  },
};
