import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { webSiteBaseURL, websiteName } from '@mr/ecourse-config';

const maxLengthDescription = 155;
const maxLengthTitle = 70;

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private doc: Document) {}

  setSiteMetaTags() {
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:name', content: 'Iuphoria' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    // this.meta.updateTag({ name: 'twitter:site', content: '@meinTwitterProfil'});
  }
  
  updateTitle(title = '') {
    if (title === '') {
      title = websiteName;
    } else {
      title = this.shortenText(title, maxLengthTitle);
    }
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'twitter:title', content: title }); // twitter
    this.meta.updateTag({ name: 'title', content: title }); // linkedIn
    this.meta.updateTag({ property: 'og:title', content: title }); // facebook (and open graph generally)
  }

  updateDescription(description = '') {
    if (description === '') {
      this.meta.removeTag(`name='description'`);
      this.meta.removeTag(`name='twitter:description'`);
      this.meta.removeTag(`property='og:description'`);
    } else {
      description = this.stripQuotationMarksFromSeoInfo(description);
      description = this.shortenText(description, maxLengthDescription);

      this.meta.updateTag({ name: 'description', content: description });
      this.meta.updateTag({ name: 'twitter:description', content: description });
      this.meta.updateTag({ property: 'og:description', content: description });
    }
  }

  shortenText(text: string, maxlength: number): string {
    if (text.length > maxlength) {
      text = text.slice(0, maxlength - 3) + '...';
    }
    return text;
  }

  stripQuotationMarksFromSeoInfo(text: string): string {
    // google seems to truncate descriptions from the point of a quotation mark:https://moz.com/learn/seo/meta-description
    const stripMe = `‘’“”„′″«»`;
    [...stripMe].forEach((character: string) => {
      text = text.split(character).join('');
    });
  
    return text;
  }

  updateCanonicalUrl() {
    let linkElement: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"');
    if (linkElement === null) {
      linkElement = this.doc.createElement('link');
      linkElement?.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(linkElement);
    }
    const canonicalURL = webSiteBaseURL + window.location.pathname;
    linkElement?.setAttribute('href', canonicalURL);
  }
}
