<div class="app-shell">
  <div class="nav-container">
    <!-- Header-Toolbar -->
    <header>
      <mat-toolbar matAutocompleteOrigin #toolbar="matAutocompleteOrigin" color="primary" class="toolbar mat-elevation-z4">
        <button #menubutton matTooltip="Menü" data-test="menuButton" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="menuClicked($event)">
          <span role="banner">
            <mat-icon aria-label="Side nav toggle icon" style="vertical-align: middle">menu</mat-icon>
          </span>
        </button>

        <!-- App-Label -->
        <a class="app-label" [routerLink]="['/']">
          <button mat-flat-button matTooltip="Startseite" color="primary" data-test="iuphoriaButton">
            <span role="banner" class="mat-title" style="padding-left: 0.5em; vertical-align: middle; margin-bottom: 0">Iuphoria</span>
          </button>
        </a>

        <!-- Search Bar -->
        <section role="search" class="search-bar-section">
          <div class=".mr-spacer fakeSearchBox" data-test="fakeSearchBox" *ngIf="!searchBoxIsLoaded" (click)="loadSearchBox($event)">
            <mat-icon matPrefix>search</mat-icon>
            <span [innerHTML]="searchBoxHintText"></span>
          </div>
          <ng-container #searchBox></ng-container>
        </section>

        <!-- Feedback Icon -->
        <span class="feedback-button">
          <button
            type="button"
            matTooltip="Feedback"
            data-test="feedbackButton"
            aria-label="feedback button"
            mat-icon-button
            (click)="routeToFeedback()">
            <mat-icon aria-label="feedback button icon" style="vertical-align: middle">mood</mat-icon>
          </button>
        </span>
      </mat-toolbar>
    </header>

    <mat-sidenav-container class="sidenav-container">
      <!-- Sidenav (menu fading in) -->
      <mat-sidenav #sidenav data-test="sidenav" class="sidenav" mode="over" fixedTopGap="56">
        <nav>
          <ng-container #legalInfoButtons></ng-container>
          <!-- <mr-course-buttons (closeSidenav)="sidenav.close()" data-test="menu-course-buttons"></mr-course-buttons> -->
          <!-- <mr-course-module-buttons data-test="menu-course-module-buttons" (closeSidenav)="sidenav.close()"></mr-course-module-buttons> -->
        </nav>
      </mat-sidenav>

      <!-- Main Content -->
      <mat-sidenav-content>
        <div id="wrapper">
          <!-- <div id="wrapper" (swipeleft)="touchEventsStore.swipe = 'left'" (swiperight)="touchEventsStore.swipe = 'right'"> -->
          <div id="main-content">
            <div id="text-max-width">
              <!-- <mr-course-module-toolbar></mr-course-module-toolbar> -->
              <main>
                <article>
                  <!-- <mr-text-content-styling> -->
                    <div *ngIf="isTestingSite" class="mr-align-center" style="background-color: red" data-test="testing-banner">
                      TESTSEITE - GEHE ZU
                      <a href="https://www.iuphoria.de">WWW.IUPHORIA.DE</a>
                    </div>
                    <img data-test="metisImg" *ngIf="metisImgSrc" [src]="metisImgSrc" style="display:none" width="0" height="0" alt="" />
                    <router-outlet (activate)="routeActivated($event)"></router-outlet>
                  <!-- </mr-text-content-styling> -->
                </article>
              </main>
              <ng-container #previousNextChapter></ng-container>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
