import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LazyInjectService } from './lazy-inject.service';
import { Angulartics2Matomo } from 'angulartics2';
import { link } from 'fs';

type Name = 'matomo' | 'metis' | 'menu' | 'searchBox' | 'prevNextChapter' | 'keywordReference' | 'normQuote' | 'normStyle';

interface Config {
  name: Name;
  delay?: number;
  onlyOnce: boolean;
  href?: string;
  isLoading?: boolean;
  id?: string;
}

const configs: Config[] = [
  {
    name: 'metis',
    delay: 200,
    onlyOnce: false,
  },
  {
    name: 'searchBox',
    delay: 3000,
    onlyOnce: true,
  },
  {
    name: 'menu',
    delay: 3100,
    onlyOnce: true,
  },
  {
    name: 'matomo',
    delay: 3200,
    onlyOnce: true,
  },
  {
    name: 'prevNextChapter',
    delay: 3300,
    onlyOnce: true,
  },
  {
    name: 'keywordReference',
    delay: 3400,
    onlyOnce: false,
  },
  {
    name: 'normQuote',
    delay: 3400,
    onlyOnce: false,
  },
];

@Injectable({
  providedIn: 'root',
})
export class EcourseLazyLoadingService {
  constructor(@Inject(DOCUMENT) private document: Document, private lazyInjector: LazyInjectService) {}
  
  public async loadMatomo() {
    const config: Config | undefined = configs.find((config: Config) => config.name === 'matomo');
    if (config !== undefined && config.isLoading !== true) {
      config.isLoading = true;
      setTimeout(async () => {
        const scriptElement: HTMLScriptElement = this.document.createElement('script');

        // tracker methods like "setCustomDimension" should be called before "trackPageView"
        // _paq.push(['trackPageView']); --> to be deleted for angulartics2
        scriptElement.textContent = `var _paq = window._paq = window._paq || [];
            _paq.push(['enableLinkTracking']);
            (function() {
              var u="//matomo.iuphoria.de/";
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', '1']);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();`;
        document.body.appendChild(scriptElement);

        // start tracking with service lazily loading angularticks-service
        const angulartics2Matomo = await this.lazyInjector.get<Angulartics2Matomo>(() => import('angulartics2').then(m => m.Angulartics2Matomo));
        angulartics2Matomo.startTracking();
      }, config.delay);
    }
  }

  public getDelay(name: Name): number | null {
    const config: Config | undefined = configs.find((config: Config) => config.name === name);

    if (config === undefined) {
      console.error('can not find config and thus delay for', name);
      return null;
    }

    if (config.isLoading === true  && config.onlyOnce === true) {
      console.error(name, ' delay for', name, ' already was requested');
      return null;
    }

    if (config.delay === undefined) {
      console.error(name, '-config is missing a delay property');
      return null;
    }

    config.isLoading = true;
    return config.delay;
  }
}
