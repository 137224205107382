import { CourseContentConfig } from "@mr/ecourse-interfaces";

export const testCourse: CourseContentConfig = {
    filenameTrunk: 'test',
    include: {
      dev: true,
      prod: false,
    },
    tocVisibility: {
      dev: true,
      prod: false,
    },
    testingOnly: true,
  };
  
  export const bgbAtCourse: CourseContentConfig = {
    filenameTrunk: 'bgb-at',
    include: {
      dev: true,
      prod: true,
    },
    tocVisibility: {
      dev: true,
      prod: false, // for now
    },
  };
  
  export const schuldrechtAtCourse: CourseContentConfig = {
    filenameTrunk: 'schuldrecht-at',
    include: {
      dev: true,
      prod: true,
    },
    tocVisibility: {
      dev: true,
      prod: false, // for now
    },
  };
  
  export const sachenrechtCourse: CourseContentConfig = {
    filenameTrunk: 'sachenrecht',
    include: {
      dev: true,
      prod: true,
    },
    tocVisibility: {
      dev: true,
      prod: false, // for now
    },
  };
  