import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { AppShellComponent } from '@mr/ecourse/app-shell/app-shell-feature';
import { DOCUMENT, NgIf } from '@angular/common';
import { EcourseLazyLoadingService } from '@mr/ecourse-lazy-loading';
import { webSiteBaseURL, websiteName } from '@mr/ecourse-config';

@Component({
  selector: 'mr-root',
  standalone: true,
  imports: [NgIf, AppShellComponent],
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(private lazyloading: EcourseLazyLoadingService, @Inject(DOCUMENT) private doc: Document) {}

  ngOnInit(): void {
    // TMP BEFORE SSR
    this.updateCanonicalUrl();
  }

  ngAfterViewInit(): void {
    this.lazyloading.loadMatomo();
  }

  updateCanonicalUrl() {
    let linkElement: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"');
    if (linkElement === null) {
      linkElement = this.doc.createElement('link');
      linkElement?.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(linkElement);
    }
    const canonicalURL = webSiteBaseURL + window.location.pathname;
    linkElement?.setAttribute('href', canonicalURL);
  }
}
